<template>
	<div class="container-wrap">
		<div class="container">
			<!--订座成功-->
			<div class="success-box" >
				<div class="success-content" v-if="OrderDetailInfo!=null">
					<p class="tit" v-if="isSuccess">
						<i class="iconfont icon-chenggong  icon-success mr20" style="font-size:50px;"></i>
						订单已经订座成功
					</p>
					<p v-else class="tit">
						<i class="iconfont icon-zhuyishixiang icon-fail mr20" style="font-size:50px;">
						</i>订单订座失败
					</p>
					<div class="txtbox">
						<div>
							<span class="txt">订单号：</span><span>{{ OrderDetailInfo.SOShortNr }}</span>
						</div>
						<div>
							<span class="txt">订单金额：</span><span
								class="price fb ft18">&yen;{{ OrderDetailInfo.OrderTotalAmount }}</span>
						</div>
					</div>
					<div class="btnbox">
						<a class="btn pay-btn" v-if="OrderDetailInfo.IsPayable" @click="bindGetPay">立即支付</a>
						<a class="btn" @click="bindJumpOrderDetail">查看订单</a>
					</div>
				</div>
				<div v-else class="serch-loading-img text-c">
					<img src="@/assets/serch_loading.gif" style="width:120px" />
					<p class="ft18 mt20">订单结果返回中，请稍等…</p>
				</div>
			</div>
			
			<!--订座失败-->
			<!-- <div class="success-box">
				<div class="success-content">
					<p class="tit"><i class="iconfont icon-zhuyishixiang icon-fail mr20"
							style="font-size:50px;"></i>订单订座失败</p>
					<div class="txtbox">
						<div><span class="txt">订单号：</span><span>PA83F9</span></div>
						<div><span class="txt">订单金额：</span><span class="price fb ft18">&yen;5180</span></div>
					</div>
					<div class="btnbox">
						<a class="btn">查看订单</a>
					</div>
					<p class="state-txt">本次订座失败,有可能是座位紧张随时变化的原因导致,稍后我们的旅行顾问会和您联系帮您完成订单预付…</p>
				</div>
			</div> -->

			<div class="advisor-choice-box" v-show="false">
				<div class="primary-txt border-bottom">旅行顾问已响应，您可以选择TA，如您 <span class="fb" id="seconds">130</span>
					秒内没选择，系统会自动推荐旅行顾问为您服务！</div>
				<div class="item-content">
					<div class="search-advisor-box">
						<div class="search-inner clearfix">
							<div class="search-item">
								<el-input type="text" class="form-control" placeholder="请输入旅行顾问的名字来查找">
									<i slot="prefix" class="el-input__icon el-icon-search"></i>
								</el-input>
							</div>
							<a class="search-btn">搜索</a>
						</div>
					</div>
					<div class="advisor-box">
						<div class="advisor-list">
							<div class="advisor-img">
								<img src="http://img2.yiqifei.com/20150414/0fd7ed03bd174d05b84f79cda95c4f48.jpg" />
							</div>
							<div class="advisor-info">
								<div><span class="name">张润荣</span><em class="online pc-online">PC在线</em><em
										class="online phone-online">移动在线</em><em class="online">接单中</em></div>
								<div class="star-level mt10">
									<span class="gray6">综合评分：</span>
									<span class="star-box">
										<!--80%表示4分，以此类推90%表示4.5分-->
										<span class="star-active" style="width:80%;"></span>
										<span class="star-bg"></span>
									</span><span class="red ml10">4.0分</span>
								</div>
								<p><span class="gray6">服务客户：</span>28965<span class="gray6 ml20">方案数：</span>28965<span
										class="gray6 ml20">点评：</span>28965</p>
								<p><span class="gray6">精通领域：</span>欧洲、南美洲、大洋洲</p>
							</div>
							<div class="select-box">
								<a class="select-btn">选择</a>
								<p class="ft14 gray6 mt10">14:28 60秒快速响应</p>
							</div>
						</div>

					</div>
				</div>
			</div>

			<div class="clearfix  position-r mt20 success-box-i" v-show="false">

				<el-row type="flex" justify="space-between" class="jiudian-box">
					<span class="title">悉尼热门酒店推荐</span>
					<div class="red"><span>更多</span><span class="iconfont icon-chalv-xiangzuo"></span></div>
				</el-row>
				<el-row>
					<el-col :span="6" v-for="(item,index) in [1,2,3,4]" :key="index">
						<div class="img-box">
							<div style="position:relative">
								<img src="http://img11.yiqifei.com/Dest/20170919/70106c68a79b4d5fb232a8a24877f69e.jpg!/both/620x360/force/true"
									alt="" style="width: 100%;
	               height: 226px;" />
								<div class="img-bottom" style="bottom:3px">
									<el-row type="flex" justify="space-between">
										<el-col :span="24">
											史卓菲行政住宿酒店
											Manly Paradise Motel and Apartments

										</el-col>

									</el-row>

								</div>
							</div>
							<div class="pd10">
								<div><span>星级：</span><span class="iconfont icon-xingxing red"></span></div>
								<div><span>地址：</span><span>54 North Steyne 2095 Manly NW Australia</span></div>
								<div>
									<span class="iconfont icon-tingchechang"></span><span class=" mr20">停车场</span>
									<span class="iconfont icon-wifi"></span><span class="mr20">wifi</span>
									<span class="iconfont icon-youyong"></span><span>游泳池</span>
								</div>
								<el-row type="flex" justify="end">
									<div class="price">
										<span>￥</span>
										<span class="ft18">912</span>
										<span>起</span>
									</div>
								</el-row>
							</div>
						</div>

					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
	var util = require('@/utils/util.js');
	var datehelper = require('@/utils/datehelper.js');
	var yqfCommon = require('@/utils/yqfCommon.js'); //通用类库

	//接口
	var orderApi = require('@/api/order.js');

	var that;

	export default {
		components: {},
		data() {
			return {
				resultTxt: '订单提交成功',
				//订单信息
				orderno: '',
				OrderDetailInfo: null,
				//倒计时
				time: 1800,
				day: '0',
				hour: '00',
				min: '00',
				second: '00',
				curEndTime: '2021-09-10 23:59:00'
			};
		},
		created() {
			that = this;
			var options = that.$route.query;
			console.log(options);
			let orderno = 'S0P377';

			if (options.id != null && options.id != undefined) {
				orderno = options.id;
			}
			that.orderno = orderno;

			if (orderno != '') {
				getOrderDetail();
				// 	let payTypeTxt = '现付，请尽快支付';

				// 	if (payTypeID == 2) {
				// 		payTypeTxt = '月结，系统正在扣款出票中...';
				// 	} else if (payTypeID == 3) {
				// 		payTypeTxt = '预付，系统正在扣现金账户余额出票中...';
				// 	}
				// 	that.orderno = orderno;
				// 	that.payTypeID = payTypeID;
				// 	that.payTypeTxt = payTypeTxt;
			} else {
				uni.showModal({
					title: '提示',
					content: '查询不到订单信息',
					showCancel: false,
					success: function(res) {
						if (res.confirm) {
							uni.reLaunch({
								url: '/pages/member/orderlist/orderlist'
							});
						}
					}
				});
			}
			
			$(window).scrollTop("0");
			
		},
		mounted() {
			// 此处true需要加上，不加滚动事件可能绑定不成功
			// window.addEventListener('scroll', this.handleScroll, true);
		},
		methods: {
			bindJumpOrderDetail:function(e){
				//https://accounts.yiqifei.com/Order/Item/SDF67A
				window.location.href="https://accounts.yiqifei.com/Order/Item/"+that.orderno;
			},
			bindGetPay:function(e){
				util.getPay(that.orderno, that.OrderDetailInfo.OrderTotalAmount);
			}
		}
	};

	//获取数据
	function getOrderDetail(id) {
		let parm = {
			id: that.orderno
		};
		orderApi.getOrderDetailByID(parm, function(result) {
			console.log(result);

			if (result.code == 0) {

				let OrderDetailInfo = result.data.OrderDetailInfo;
				console.log(OrderDetailInfo);

				that.OrderDetailInfo = OrderDetailInfo;
				//判断是否订座成功
				if (yqfCommon.isNullOrEmpty(OrderDetailInfo.PNR)) {
					that.isSuccess = false
				} else {
					that.isSuccess = true;
				}
				// OrderDetailInfo.PNR = "";
				that.OrderDetailInfo.IsPayable=true;
				// if (OrderDetailInfo.PNR == null || OrderDetailInfo.PNR == '') {
				// 	if (that.countDownDone == false) {
				// 		that.isCountdown = true;
				// 		setcountDown();
				// 	} else {
				// 		console.log('订单订座失败');
				// 		that.resultTxt = '订单订座失败';
				// 		that.isSuccess = false;
				// 	}
				// } else {
				// 	that.isSuccess = true;
				// 	that.countDownDone = true;
				// 	that.resultTxt = '订座成功';
				// }
				// if (OrderDetailInfo.IsPayable) {
				// 	that.curEndTime = OrderDetailInfo.PayEndTime;
				// 	var time_now = new Date().addMin(30);
				// 	that.curEndTime = time_now;
				// 	payCountTime();
				// }
				//写入统计
				try {
					var trackPostData = {
						BrowseOrder: {
							BookerID: OrderDetailInfo.BookerID,
							SOShortNr: OrderDetailInfo.SOShortNr,
							TotalAmount: OrderDetailInfo.OrderTotalAmount,
							CurrencyID: 4,
							OrderDate: OrderDetailInfo.OrderDate
						}
					};
					// util.getYqfTrack(trackPostData);
				} catch (e) {}
			} else {
				that.$alert(result.msg);
			}
		});
	}
</script>

<style lang="scss">
	@import '../../style/Flight/flight.scss';
</style>
